ol {
  margin-top: 1rem;
  list-style-position: inside;
}

li {
  a {
    color: white;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: var(--primary);
    }
  }
}

table {
  width: 100%;
  margin-top: 1rem;
}

thead {
  margin-bottom: 1rem;
}

th {
  text-align: left;
}
