.box {
  // margin-bottom: 2rem;
}

.input-wrapper {
  position: relative;
  margin: 20px auto;
}

.form-control {
  display: block;
  line-height: 2rem;
  height: 3rem;
  padding-left: 10px;
  font-size: medium;
  border: none;
  background-color: var(--bg-secondary);
  border-radius: 0.25rem;
  font-weight: 500;
  color: white;

  &::placeholder {
    color: var(--text-primary);
  }
}

.form-control:focus {
  border: 4px solid var(--primary);
  outline: none;
  padding-left: 6px;
}

.control-label {
  display: block;
  position: absolute;
  opacity: 0;
  bottom: 1.9rem;
  color: white;
  transition: 0.2s ease-in-out transform;
  font-size: 12px;
}

.form-control:placeholder-shown + .control-label {
  visibility: hidden;
  z-index: -1;
  transition: 0.2s ease-in-out;
}

.form-control:not(:placeholder-shown) + .control-label,
.form-control:focus:not(:placeholder-shown) + .control-label {
  visibility: visible;
  z-index: 1;
  opacity: 1;
  transform: translateY(-1.5rem);
  transition: 0.2s ease-in-out transform;
  color: white;
}
