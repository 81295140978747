.form {
  display: flex;
  margin-top: 1rem;
  // gap: 1rem;
}

.container {
  // max-width: 1200px;
  // margin: auto;
}

.left {
  display: flex;
  flex-direction: column;
  max-width: 200px;
  margin-right: 1rem;

  label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }
}

.button {
  background-color: var(--text-primary);
  color: var(--bg-primary);
  padding: 0.25rem 0.5rem;
  margin-top: -0.25rem;
  border-radius: 0.25rem;
  cursor: pointer;
  border: 0;
  line-height: 1.5rem;
  font-size: 1rem;

  &:hover {
    background-color: var(--text-secondary);
  }

  &.button--primary {
    --text-primary: var(--primary);
    --text-secondary: var(--primary-hover);
    color: white;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

.upload-file-text {
  width: 100%;
  text-align: center;
}

.right {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dropzone {
  background-color: var(--bg-secondary);
  height: 200px;
  width: 200px;
  outline: 2px dashed currentColor;
  outline-offset: -0.75rem;
  border-radius: 0.5rem;
  transition: all 0.15s ease-in-out;

  display: flex;
  justify-content: center;
  align-items: center;

  // cursor: pointer;

  svg {
    pointer-events: none;
    width: 40%;
    height: 40%;
  }

  &.active {
    color: var(--primary);
    outline-offset: -1rem;
  }

  &.done {
    background: linear-gradient(
      0deg,
      yellowgreen 0%,
      yellowgreen 100%
    ) !important;
  }
}

.info {
  margin-top: 2rem;

  h3 {
    margin-bottom: 1rem;
  }

  .clipboard__copy {
    margin-left: 1rem;
    color: yellowgreen;
  }
}

input {
  width: 100%;
  padding: 0.5rem;
}

.Toastify__toast--success {
  background-color: yellowgreen;
  border-radius: 0.5rem;
}
