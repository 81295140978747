.background {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  z-index: -10;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.login-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 2rem);
  margin-right: 5rem;

  @media only screen and (max-width: 600px) {
    margin-right: 0;
  }
}

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 1rem;
  backdrop-filter: blur(100px);
  box-shadow: 0 0 500px -50px black;

  animation: reveal 1s forwards;
}

@keyframes reveal {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.login-logo {
  img {
    width: 100%;
    pointer-events: none;
    user-select: none;
  }
}

.login-heading {
  text-align: center;
  font-size: 1.5rem;
  margin-top: -2rem;
  margin-bottom: 3rem;
  font-weight: normal;
}

.login-btn {
  width: 100%;
  padding: 1rem;
  border: 2px solid white;
  border-radius: 0.5rem;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  &:hover {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    color: white !important;
  }
}

.download-indicator {
  width: 100%;
  padding: 1rem;
  border: 2px solid white;
  border-radius: 0.5rem;
  background-color: transparent;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1rem;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;
}
