/* @import url('https://use.typekit.net/xyx1evd.css'); */

@font-face {
  font-family: 'acumin-pro';
  src: url('https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6d4bb2/00000000000000003b9acafc/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'acumin-pro';
  src: url('https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('woff'),
    url('https://use.typekit.net/af/6ce26b/00000000000000003b9acafd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'acumin-pro';
  src: url('https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/aa5b59/00000000000000003b9acaf7/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3')
      format('opentype');
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'acumin-pro';
  src: url('https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff2'),
    url('https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('woff'),
    url('https://use.typekit.net/af/46da36/00000000000000003b9acaf6/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
      format('opentype');
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

:root {
  font-size: 16px;
  font-family: acumin-pro;
  --text-primary: #b6b6b6;
  --text-secondary: #ececec;
  --bg-primary: #23232e;
  --bg-secondary: #141418;
  --bg-dark: #202020;
  --transition-speed: 600ms;
  --primary: #ff3331;
  --primary-hover: red;
  --primary: #ff3c00;
  --primary-hover: red;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  color: white;
  background-color: var(--bg-dark);
}

main {
  margin-left: 5rem;
  padding: 1rem;
}

.u-t-primary {
  color: var(--primary);
}

.u-mt-auto {
  margin-top: auto !important;
}
