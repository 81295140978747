.skeleton {
  width: 100%;
  background-color: #333;
  height: 1rem;
  border-radius: 0.25rem;
  animation: pulse 1s infinite alternate;
  margin: 0.5rem 0;
}

@keyframes pulse {
  from {
    background-color: #333;
  }

  to {
    background-color: #666;
  }
}
